<template>
  <HeaderNavbar
    :is-sticky="isSticky"
    :sticky-header="true"
    :sticky-hide="true"
    :has-gradient="true"
    :title="siteName"
    :logo-svg="siteSvgLogo"
    :logo-path="siteImgLogo"
    :logo-link="{ name: 'dashboard' }"
    :logoProperties="{ maxFontSize: 36 }"
  >
    <template #header-left>
      <IButton class="sf-header__menu-btn header-icon" @click="openSidebarMenu">
        <MenuIcon />
      </IButton>
    </template>
    <template #header-right>
      <IDropdown
        v-if="user"
        class="sf-header__profile header-icon"
        :is-open="dropdown"
        :persistent="dropdownPersistent"
        @click:close="closeProfile"
      >
        <template #opener>
          <IButton
            class="sf_header__profile-icon header-icon"
            @click="openProfile"
            @mouseenter="dropdownPersistent = true"
            @mouseleave="dropdownPersistent = false"
          >
            <div class="header-avatar">
              <img
                v-if="user.photo && !loadError"
                :src="user.photo"
                :alt="user.name"
                class="header-avatar_img"
                @error="loadError = true"
              >
              <ProfileIcon v-else />
              <!-- <img src="/img/avataaars.png" class="c-avatar-img" />-->
            </div>
          </IButton>
        </template>
        <template #cancel><div /></template>
        <SfList class="sf-header__profile-menu">
          <SfListItem>
            <IButton class="sf-button--full-width">
              {{ user.name }}
            </IButton>
          </SfListItem>
          <SfListItem>
            <IButton class="sf-button--full-width" @click="changePassword">
              {{ $t('forms.links.change_password') }}
            </IButton>
          </SfListItem>
          <SfListItem>
            <IButton class="sf-button--full-width" @click="logout">
              {{ $t('header.links.logout') }}
            </IButton>
          </SfListItem>
        </SfList>
      </IDropdown>
    </template>
  </HeaderNavbar>
</template>

<script>
import { SfList } from '@storefront-ui/vue'
import MenuIcon from '@/components/icons/MenuIcon';
import { mapActions, mapGetters } from 'vuex';
import ProfileIcon from '@/components/icons/ProfileIcon.vue';
import { IButton, IDropdown, ILogo, HeaderNavbar } from 'instashop-ui';
import { SITE_NAME, IMG_LOGO, SVG_LOGO } from '@/settings';

export default {
  name: 'SiteHeader',
  props: {
    isSticky: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadError: false,
      dropdown: false,
      dropdownOutsideClosed: false,
      dropdownPersistent: true,
      icons: [],
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300
    };
  },
  components: {
    ProfileIcon,
    MenuIcon,
    IButton,
    IDropdown,
    SfList,
    HeaderNavbar
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    siteName() {
      return SITE_NAME;
    },
    siteImgLogo() {
      return IMG_LOGO;
    },
    siteSvgLogo() {
      return SVG_LOGO;
    }
  },
  methods: {
    ...mapActions({
      logoutUser: 'user/logoutUser'
    }),
    logout () {
      this.logoutUser({ redirect: true });
    },
    openSidebarMenu () {
      this.$store.dispatch('main/sidebarShow', true)
    },
    changePassword() {
      this.$store.dispatch('user/changePasswordModal', true)
      this.closeProfile()
    },
    openProfile (event) {
      if (event) event.stopPropagation()
      this.dropdown = !this.dropdown;
      this.dropdownOutsideClosed = false
    },
    closeProfile () {
      if (!this.dropdown) return
      this.dropdownOutsideClosed = true
      this.dropdown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header-avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  overflow: hidden;

  &_img {
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
}


.sf-header {
  &__profile {
    --dropdown-position: relative;
    --dropdown-container-position: absolute;
    --dropdown-container-top: 100%;
    --dropdown-container-width: auto;
    --overlay-z-index: 1;

    ::v-deep .sf_header__profile-icon {
      --button-padding: var(--spacer-sm);
      --button-background: transparent;
      --icon-size: 2rem;
    }

    ::v-deep .sf-dropdown__container {
      right: 0;
      box-shadow: 0 3px 10px rgb(0, 0, 0, .2);
      border: 1px solid #d8dbe0;
      min-width: 10rem;
    }

    ::v-deep .sf-dropdown__overlay {
      display: none;
    }

    &-menu {
      --button-color: var(--theme-primary);
    }

    ::v-deep .sf-dropdown-enter-active {
      animation: var(--dropdown-animation-enter, dropdowned 150ms);
    }
    ::v-deep .sf-dropdown-leave-active {
      animation: var(--dropdown-animation-leave, dropdowned 150ms reverse);
    }

    @keyframes dropdowned {
      0% {
        opacity: 0;
        //transform: translate3d(100%, 0, 0);
      }
      100% {
        opacity: 1;
        //transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
