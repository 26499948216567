<template>
  <Toast
    class="p-toast"
    ref="toast"
    :position="position"
    :baseZIndex="1000"
    :life="3000"
  />
</template>

<script>
import Toast from 'primevue/toast';

export default {
  name: 'IToast',
  components: {
    Toast
  },
  props: {
    position: {
      type: String,
      default: 'top-right'
    }
  }
}
</script>

<style lang="scss">
.p-toast {
  .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;

    .p-toast-message-content {
      padding: .5rem .5rem .75rem .5rem;
      border-width: 0 0 0 6px;

      .p-toast-message-text {
        margin: 0 0 0 .75rem;
        padding: .25rem .25rem 0 0;
      }

      .p-toast-message-icon {
        font-size: 1.5rem;
        margin: .25rem 0 0;
      }

      .p-toast-summary {
        font-weight: 700;
        margin-bottom: .25rem;
        display: block;
      }

      .p-toast-detail {
        margin: 0;
      }
    }

    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      box-sizing: border-box;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
      }
    }

    &.p-toast-message-info {
      background: rgba(var(--theme-info-base), .9);
      border: solid var(--theme-info);
      border-width: 0 0 0 6px;
      color: var(--theme-info-text, var(--theme-white));

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: var(--theme-info-text, var(--theme-white));;
      }
    }

    &.p-toast-message-success {
      background: rgba(var(--theme-success-base), .9);
      border: solid var(--theme-success);
      border-width: 0 0 0 6px;
      color: var(--theme-success-text, var(--theme-white));

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: var(--theme-success-text, var(--theme-white));
      }
    }

    &.p-toast-message-warn {
      background: rgba(var(--theme-warning-base), .9);
      border: solid var(--theme-warning);
      border-width: 0 0 0 6px;
      color: var(--theme-warning-text, var(--theme-black));

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: var(--theme-warning-text, var(--theme-black));
      }
    }

    &.p-toast-message-error {
      background: rgba(var(--theme-danger-base), .9);
      border: solid var(--theme-danger);
      border-width: 0 0 0 6px;
      color: var(--theme-danger-text, var(--theme-white));

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: var(--theme-danger-text, var(--theme-white));
      }
    }
  }
}
</style>
