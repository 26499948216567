<template>
  <div class="layout">
    <SiteHeader class="layout--header" is-sticky />
    <AdminPanel
      v-if="userHasRole('admin')"
      class="layout--admin-panel"
      :lang="$i18n.locale"
      :current-url="currentUrl"
    />
    <MainSidebar
      :visible="isSidebarOpen"
      @close="closeSidebar"
    />
    <div class="layout--viewport">
      <SiteBreadcrumbs />
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </div>
    <ChangePasswordModal
      :visible="userChangePasswordModal"
      @close="$store.dispatch('user/changePasswordModal', false)"
    />
    <IToast />
  </div>
</template>

<script>
import { SfHeader, SfFooter, SfSidebar, SfHeading, SfList, SfButton } from '@storefront-ui/vue'
import SiteHeader from '@/components/organisms/SiteHeader';
import { mapGetters } from 'vuex';
import localeSwitcher from '@/components/common/localeSwitcher';
import LanguageSelector from '@/components/organisms/LanguageSelector';
import MainSidebar from '@/components/organisms/MainSidebar';
import SiteBreadcrumbs from '@/components/organisms/SiteBreadcrumbs';
import SpectatorLinks from '@/components/organisms/SpectatorLinks.vue';
import LatestBuildAlerts from '@/components/organisms/LatestBuildAlerts.vue';
import AdminPanel from 'admin-panel'
import IToast from '@/components/atoms/IToast.vue';
import ChangePasswordModal from "@/components/templates/ChangePasswordModal.vue";

export default {
  name: 'MainLayout',
  components: {
    ChangePasswordModal,
    IToast,
    SpectatorLinks,
    SiteBreadcrumbs,
    MainSidebar,
    LanguageSelector,
    localeSwitcher,
    SiteHeader,
    SfHeader,
    SfFooter,
    SfSidebar,
    SfHeading,
    SfList,
    SfButton,
    AdminPanel,
    LatestBuildAlerts
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: 'main/isSidebarOpen',
      userHasRole: 'user/hasRole',
      userChangePasswordModal: 'user/changePasswordModal'
    }),
    currentUrl () {
      return (window ? window.location.origin : '') + this.$route.fullPath
    }
  },
  methods: {
    closeSidebar () {
      this.$store.dispatch('main/sidebarShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
.layout {
  &--header {
  }

  &--admin-panel {
    --admin-panel-menu-z-index: 9;
  }

  &--spectator {
    max-width: 36rem;
    margin: var(--spacer-xs) auto var(--spacer-base);
  }

  &--viewport {
    max-width: var(--header-width, var(--container-max-width, 77.5rem));
    margin: 0 auto;
    padding: var(--spacer-sm);
  }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
