<template>
  <div class="error-page">
    <div class="error-form__wrapper">
      <div class="error-form">
        <div class="error-form--header">
          <PresentationHeading :title="$t(title)" :level="2"/>
        </div>
        <div class="error-form--body">
          <p v-html="$t(description)" />
        </div>
        <div class="error-form--footer">
          <IButton :link="{ name: 'dashboard' }">
            {{ $t('error.go_home') }}
          </IButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PresentationHeading from "@/components/atoms/PresentationHeading.vue";
import { IHeading, IButton } from "instashop-ui";

export default {
  name: 'ErrorPage',
  components: {
    PresentationHeading,
    IHeading,
    IButton
  },
  props: {
    title: {
      type: String,
      default: 'title.error'
    },
    description: {
      type: String,
      default: 'error.description'
    }
  }
}
</script>

<style lang="scss">
.error {
  &-page {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 9.375rem);
    align-items: center;
    justify-content: center;
  }

  &-form {
    &__wrapper {
      display: flex;
      flex-direction: column;
      margin: var(--spacer-base) auto;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      background: var(--theme-white);
      border-radius: var(--theme-border-radius);
      width: 25rem;
    }

    &--header {
      --heading-margin: 0;
      margin-bottom: var(--spacer-sm);
      border-bottom: 1px solid var(--theme-light);
      padding: var(--spacer-base) var(--spacer-base) var(--spacer-xs);
    }

    &--body {
      padding: var(--spacer-xs) var(--spacer-base);
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacer-xs);
      flex-direction: column;
      align-items: center;

      p {
        white-space: pre-line;
      }
    }

    &--footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: var(--spacer-xs) var(--spacer-base) var(--spacer-base);
    }
  }
}
</style>
