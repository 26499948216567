<template>
  <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1H26.5M1.5 8H26.5M1.5 15H26.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'MenuIcon'
}
</script>

<style scoped lang="scss">
svg {
  path {
    stroke: var(--icon-color, white)
  }
}
</style>
