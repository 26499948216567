import router from './router'
import store from './store'
import { getUser } from "@/api/users.api";

function isUserHasPermissionToRoute(user, route) {
  if (user && (user.role === 'admin' || (user.roles || []).includes('admin'))) {
    return true;
  }
  if (route && route.meta && route.meta.roles && route.meta.roles.length > 0) {
    return route.meta.roles.some(role => user && ((user.roles || []).includes(role) || user.role === role));
  }
  return true
}

const whiteList = ['/register', '/login', '/forgot', '/401', '/403', '/404']

router.beforeEach(async (to, from, next) => {
  if (to.query && to.query.token) {
    await store.dispatch('user/loginByToken', { token: to.query.token })
      .then(() => {
        return next({ path: to.path });
      })
      .catch((e) => console.error(e))
  }

  if (!localStorage.token) {
    if (whiteList.includes(to.path)) {
      return next();
    } else {
      return next({ path: '/login' });
    }
  }

  if (!store.getters['user/getUser']) {
    try {
      await getUser().then(async ({ data }) => {
        await store.dispatch('user/setUser', data.user)
      })
    } catch (e) {
      store.commit('user/LOG_OUT');
      return next({ path: '/login' });
    }
  }

  if (to.name === 'dashboard') {
    if (store.getters['user/getUser'] && store.getters['user/getUser'].username === 'ycombinator') {
      next({ name: 'site-add' });
    } else if (store.getters['user/hasRole']('deployer', false)) {
      next({ name: 'sites' });
    } else if (store.getters['user/hasRole']('supplier', false)) {
      next({ name: 'brand-cabinet' });
    } else {
      next()
    }
  } else if (isUserHasPermissionToRoute(store.getters['user/getUser'], to)) {
    next();
  } else {
    next({ path: '/403' });
  }
});
