<template>
  <i class="dot-separator" />
</template>

<script>
export default {
  name: 'Dot'
}
</script>

<style lang="scss">
.dot-separator {
  display: inline-block;
  margin: var(--dot-margin, 0 .25rem);
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: var(--dot-width, var(--dot-size, .25rem));
    height: var(--dot-height, var(--dot-size, .25rem));
    background: var(--dot-background, var(--theme-gray-lighten));
    border-radius: var(--dot-border-radius, 50%);
    transform: var(--dot-transform, translate(-0.125rem, -0.375rem));
  }
}
</style>
