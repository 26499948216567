<template>
  <SfSidebar class="sidebar" :visible="visible" @close="$emit('close')" :title="$t('header.menu')">
    <SfList class="sidebar--menu">
      <SfListItem
        v-for="item in sidebarItems"
        :key="item.title"
        class="sidebar--menu-li"
        @click.native="$emit('close')"
      >
        <SfButton class="sf-button--full-width sidebar--menu-li-button" :link="item.to">
          {{ $t('header.links.' + item.title) }}
        </SfButton>
      </SfListItem>
    </SfList>
    <div class="sidebar--language">
    <!--  <SfHeading :level="4" :title="$t('langs._select')" class="sf-heading&#45;&#45;left" />-->
    <!--  <LanguageSelector class="sidebar&#45;&#45;language-select" />-->
    </div>
    <div class="sidebar--profile">
      <SfButton class="sf-button--full-width sf-button--pure" @click="logout">
        {{ $t('header.links.logout') }}
      </SfButton>
    </div>
  </SfSidebar>
</template>

<script>
import { SfSidebar, SfList, SfButton, SfHeading } from '@storefront-ui/vue';
// import LanguageSelector from '@/components/organisms/LanguageSelector';
import getSidebarItems from '@/data/sidebar-nav-items';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MainSidebar',
  components: {
    SfSidebar,
    SfHeading,
    SfList,
    SfButton,
    // LanguageSelector
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      userRoles: 'user/roles'
    }),
    sidebarItems() {
      return getSidebarItems().filter(item => {
        if (item.roles && item.roles.length) {
          return item.roles.some(role => this.userRoles.indexOf(role) !== -1)
        }
        return true;
      })
    }
  },
  methods: {
    ...mapActions({
      logoutUser: 'user/logoutUser'
    }),
    logout () {
      this.logoutUser({ redirect: true });
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  --sidebar-content-padding: var(--spacer-base);

  @media screen and (max-width: 767px) {
    --sidebar-content-padding: var(--spacer-base) var(--spacer-sm);
  }

  ::v-deep .sf-bar.smartphone-only {
    --bar-padding: 0 var(--spacer-base) 0 var(--spacer-xs);

    .sf-bar__icon.sf-button.sf-button--pure {
      padding: var(--spacer-sm);
    }
  }

  ::v-deep .sf-sidebar__circle-icon {
    position: absolute;
    padding: 0;
  }

  &--menu {
    margin-bottom: 1rem;

    &-li {
      --button-background: transparent;
      --button-color: var(--theme-primary);
      margin-bottom: .5rem;

      &-button {
        justify-content: start;
        --button-padding: var(--spacer-sm) 1.25rem;

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  &--language {
    margin-top: auto;
    margin-bottom: 1.5rem;

    &-select {
      border: 1px solid #eee;
      border-radius: 1rem;
      padding: .25rem .25rem 0 .75rem;
    }
  }

  &--profile {
    .sf-button--pure {
      padding: var(--spacer-xs);
    }
  }
}
</style>
