<template>
  <div>
    <CSelect :value="selected" :options="options" class="mb-0" @update:value="changeLang"></CSelect>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          label: this.$t("langs.en"),
          value: "en",
        },
        {
          label: this.$t("langs.ru"),
          value: "ru",
        },
      ],
    };
  },
  methods: {
    changeLang(value) {
      if (localStorage.lang != value) {
        localStorage.lang = value;
        this.$i18n.locale = value;
      }
    },
  },
  computed: {
    selected() {
      return this.$i18n.locale;
    },
  },
};
</script>