import httpClient from './httpClient';

const END_POINT = '/api/users';

const loginByToken = ({ token }) => httpClient.post(`${END_POINT}/login/token`, { token });
const loginUser = data => httpClient.post(`${END_POINT}/login`, data);
const registerUser = data => httpClient.post(`${END_POINT}/register`, data);
const changePassword = data => httpClient.post(`${END_POINT}/password`, data);
const forgotPassword = data => httpClient.post(`${END_POINT}/forgot`, data);

export { loginUser, loginByToken, registerUser, changePassword, forgotPassword };
