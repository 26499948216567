<template>
  <div class="spectator">
    <SfHeading class="spectator-title" :level="4" :title="$t('spectator._title')" />
    <div class="spectator-links">
      <div class="spectator-links--item access">
        <a href="https://drive.google.com/open?id=1_q35VVENZLGSmvUEa5zbDvZoOhWcAVQ8&usp=drive_fs" target="_blank">
          <i class="icon"><KeyIcon /></i>
          <span>{{ $t('spectator.access') }}</span>
        </a>
      </div>
      <div class="spectator-links--item slack">
        <a href="https://drive.google.com/open?id=1_queT9Qgde5OGBPJbvo2HQKQwP0vNTk5&usp=drive_fs" target="_blank">
          <i class="icon"><SlackIcon /></i>
          <span>{{ $t('spectator.slack') }}</span>
        </a>
      </div>
      <div class="spectator-links--item jira">
        <a href="https://drive.google.com/open?id=1_vNU3ZkGeYZjXkq4o6tBTbKn0yanQPoB&usp=drive_fs" target="_blank">
          <i class="icon"><JiraIconNew /></i>
          <span>{{ $t('spectator.jira') }}</span>
        </a>
      </div>
      <div class="spectator-links--item jira">
        <a href="https://drive.google.com/open?id=1aykL7j1UPz8c7wXH9azjPOdf9XdDAftH&usp=drive_fs" target="_blank">
          <i class="icon"><GitlabIcon /></i>
          <span>{{ $t('spectator.git') }}</span>
        </a>
      </div>
      <div class="spectator-links--item docs">
        <a href="https://drive.google.com/open?id=1_xKCX5CqxCcDhI7WyuXT3cxdMAV0DGLy&usp=drive_fs" target="_blank">
          <i class="icon"><GoogleDocsIcon /></i>
          <span>{{ $t('spectator.meeting') }}</span>
        </a>
      </div>
      <div class="spectator-links--item docs">
        <a href="https://drive.google.com/open?id=1_z63GqCMBr1zVdRwaHIKJ6xARepQzJQN&usp=drive_fs" target="_blank">
          <i class="icon"><GoogleDocsIcon /></i>
          <span>{{ $t('spectator.instruction') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { SfHeading } from '@storefront-ui/vue';
import KeyIcon from '@/components/icons/KeyIcon.vue';
import SlackIcon from '@/components/icons/SlackIcon.vue';
import JiraIcon from '@/components/icons/JiraIcon.vue';
import GoogleDocsIcon from '@/components/icons/GoogleDocsIcon.vue';
import JiraIconNew from '@/components/icons/JiraIconNew.vue';
import GitlabIcon from '@/components/icons/GitlabIcon.vue';

export default {
  name: "SpectatorLinks",
  components: {
    GitlabIcon,
    JiraIconNew,
    GoogleDocsIcon,
    JiraIcon,
    SfHeading,
    SlackIcon,
    KeyIcon
  }
}
</script>


<style scoped lang="scss">
.spectator {
  &-title {
    --heading-padding: 0;
    text-transform: uppercase;
    //margin-bottom: var(--spacer-sm);
  }

  &-links {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-top: 1px solid #d8dbe0;
    border-radius: 0.25rem;

    @media (max-width: 480px) {
      margin-left: -.75rem;
      margin-right: -.75rem;
    }

    &--item {
      flex: 16.66%;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      align-items: stretch;
      justify-content: center;

      svg {
        max-height: 6rem;
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: var(--spacer-xs);
      }

      &.slack {
        svg {
          transform: translate(-50%, -50%) scale(1.5);
        }
      }

      &.jira {
        svg {
          transform: translate(-50%, -50%) scale(0.9);
        }
      }

      &.docs {
        svg {
          transform: translate(-50%, -50%) scale(0.65);
        }
      }

      a {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        color: var(--theme-black);
        font-weight: 500;

        .icon {
          background: var(--theme-white);
          width: 100%;
          position: absolute;
          padding-top: 100%;
          border-left: 1px solid #d8dbe0;
          border-bottom: 1px solid #d8dbe0;
        }

        span {
          margin-top: 100%;
          padding-top: var(--spacer-xs);
          text-align: center;
          line-height: 1.25;
          min-height: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            padding-top: 0;
            font-size: .8125em;
          }

          @media (max-width: 480px) {
            font-size: .75em;
          }
        }
      }

      &:last-child {
        a {
          .icon {
            border-right: 1px solid #d8dbe0;
          }
        }
      }


      &.docs {
        a {
          .icon {
            //padding: 1.25rem;
          }
        }
      }
    }
  }
}
</style>
