<template>
  <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :clip-path="`url(#ru_clip_${_uid})`">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H19.6667V15H1V1Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 5.66699H19.6667V15.0003H1V5.66699Z" fill="#0039A6" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 10.333H19.6667V14.9997H1V10.333Z" fill="#D52B1E" />
    </g>
    <rect v-if="border" x="0.5" y="0.5" width="19.6667" height="15" stroke="var(--icon-border, #F2F2F2)" />
    <defs>
      <clipPath :id="`ru_clip_${_uid}`">
        <rect x="1" y="1" width="18.6667" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RU',
  props: {
    border: { type: Boolean, default: true }
  }
}
</script>
