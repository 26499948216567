<template>
  <SfBreadcrumbs
    v-if="breadcrumbs.length > 1"
    :breadcrumbs="breadcrumbs"
    class="breadcrumbs"
  />
</template>

<script>
import { SfBreadcrumbs } from '@storefront-ui/vue'

export default {
  name: 'SiteBreadcrumbs',
  components: {
    SfBreadcrumbs
  },
  computed: {
    routes () {
      return this.$router.getRoutes()
    },
    breadcrumbs () {
      return this.$route.matched.map(route => {
        if (!route.name && route.redirect) {
          route = this.routes.find(r => r.name === route.redirect)
        }
        const routeArr = []
        if (!route) {
          return routeArr
        }
        if (route.meta && route.meta.parent) {
          const parent = this.routes.find(r => r.name === route.meta.parent)
          if (parent) {
            if (!parent.meta) {
              parent.meta = {}
            }

            if (parent.meta.parent) {
              const grandParent = this.routes.find(r => r.name === parent.meta.parent)
              if (grandParent) {
                if (!grandParent.meta) {
                  grandParent.meta = {}
                }
                routeArr.push({
                  name: grandParent.name,
                  text: this.$t(`title.${grandParent.meta.breadcrumb || grandParent.name}`),
                  link: { name: grandParent.name, params: this.$route.params },
                })
              }
            }

            routeArr.push({
              name: parent.name,
              text: this.$t(`title.${parent.meta.breadcrumb || parent.name}`),
              link: { name: parent.name, params: this.$route.params },
            })
          }
        }
        return routeArr.concat({
          name: route.name,
          text: this.$t(`title.${route.meta.breadcrumb || route.name}`),
          link: { name: route.name, params: this.$route.params },
        })
      })
        .flat()
        .filter(route => route.name)
        .filter((route, i, arr) => i === arr.findIndex(r => r.name === route.name))
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfBreadcrumbs.scss";

.breadcrumbs {
  margin: var(--spacer-xs) 0 var(--spacer-base);
  --breadcrumbs-font-size: var(--font-size--base);
}
</style>
