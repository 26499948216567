export default function () {
    return [
        // {
        //     title: 'dashboard',
        //     to: {
        //         name: 'dashboard',
        //     },
        // },
        {
            title: 'sites',
            to: {
                name: 'sites',
            },
            roles: ['deployer', 'admin']
        },
        {
            title: 'site-add',
            to: {
                name: 'site-add',
            },
            roles: ['deployer', 'admin']
        },
        {
            title: 'brand-list',
            to: {
                name: 'brand-list',
            },
            roles: ['admin']
        },
        {
          title: 'get-started',
          to: {
            name: 'brand-get-started',
          },
          roles: [
            'supplier'
          ]
        },
        {
            title: 'brand-cabinet',
            to: {
                name: 'brand-cabinet',
            },
            roles: ['supplier']
        },
        {
            title: 'brand-products',
            to: {
                name: 'brand-products',
            },
            roles: ['supplier']
        },
        {
            title: 'brand-sales',
            to: {
                name: 'brand-sales',
            },
            roles: ['supplier']
        },
        {
            title: 'brand-shipments',
            to: {
                name: 'brand-shipments',
            },
            roles: ['supplier']
        },
        {
            title: 'brand-payouts',
            to: {
                name: 'brand-payouts',
            },
            roles: ['supplier']
        }
    ];
}
