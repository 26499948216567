<template>
  <svg width="59" height="69" viewBox="0 0 59 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.460933 55.2341L11.8178 51.8577C15.7799 50.3439 18.2464 49.1791 17.7106 43.2863C16.5381 40.8452 16.2261 39.3001 16.1035 36.322C14.3345 37.5421 12.8892 36.322 12.8892 33.1077C10.2106 24.5363 13.4249 24.5363 13.4249 24.5363C13.4249 24.5363 13.4249 20.2506 13.9606 14.3577C14.4964 8.46484 18.2464 0.964844 28.4249 0.964844C38.6035 0.964844 42.3535 8.46484 42.8892 14.3577C43.4249 20.2506 43.4249 24.5363 43.4249 24.5363C43.4249 24.5363 46.6392 24.5363 43.9606 33.1077C43.9606 36.322 42.5154 37.5421 40.7464 36.322C40.6238 39.3001 40.3117 40.8452 39.1392 43.2863C38.6035 49.1791 41.0699 50.3439 45.0321 51.8577L58.1064 55.7446C51.2277 63.8539 40.9631 69.0006 29.4964 69.0006C17.7908 69.0006 7.33789 63.6373 0.460933 55.2341Z" fill="#ffffff" fill-opacity="0.2" />
  </svg>
</template>

<script>
export default {
  name: 'ProfileIcon'
}
</script>

<style scoped lang="scss">
svg {
  width: var(--icon-width, var(--icon-size, 59px));
  height: var(--icon-height, var(--icon-size, 69px));
  path {
    fill: var(--icon-color, #ffffff);
    fill-opacity: var(--icon-opacity, 1);
  }
}
</style>
