<template>
  <div v-if="latestBuild" class="latest-build" :class="{ 'latest-build--has-error': hasErrors }">
    <div v-if="!hasErrors" class="latest-build--success">
      <div class="latest-build--success-title">
        <IHeading :title="$t('site.build.status')" bold />
        <IAlert type="success" :message="$t('site.build.success.title')" />
      </div>
      <p class="latest-build--success-subtitle">
        {{ $t('site.build.success.subtitle', { timeAgo: buildTimeAgo }) }}
      </p>
    </div>
    <div v-else class="latest-build--error">
      <IHeading
        :title="$t('site.build.error.title')"
        :hint="makeHint('site.build.error.title')"
        bold
      />
      <p class="latest-build--error-subtitle" v-html="$t('site.build.error.subtitle', {
        timeAgo: `<b>${buildTimeAgo || errorHappenedAgo}</b>`,
        errorAgo: `<b>${errorHappenedAgo}</b>` })"
      />
      <p class="latest-build--error-subtitle" v-html="$t('site.build.error.working', { inTime: timeToResolve })" />
    </div>
    <div v-if="userHasRole('admin')" class="latest-build--toggle">
      <!--      <IHeading-->
      <!--        :title="$t('site.build.admin.title')"-->
      <!--        :hint="makeHint('site.build.admin.title')"-->
      <!--        bold-->
      <!--      />-->
      <ISwitcher
        :value="hasErrors"
        :label="hasErrors ? $t('site.build.admin.clear_errors') : $t('site.build.admin.enable_maintenance')"
        :hint="makeHint(hasErrors ? 'site.build.admin.clear_errors' : 'site.build.admin.enable_maintenance')"
        @input="toggleBuild"
      />
      <TextInput
        v-if="hasErrors"
        class="latest-build--hours"
        type="number"
        name="hours"
        :value="hoursToResolve"
        :label="$t('site.build.admin.hours_to_resolve')"
        :hint="makeHint('site.build.admin.hours_to_resolve')"
        :icon="hasErrors ? { icon: 'check', color: String(hoursToResolve) !== String(hoursToErrorResolve) ? 'var(--theme-primary)' : 'var(--theme-gray)', size: 'lg' } : undefined"
        @input="hoursToResolve = $event"
        @click:icon="changeHours"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { IAlert, IButton, IHeading, ISwitcher, TextInput } from 'instashop-ui'
import PresentationHeading from '../atoms/PresentationHeading.vue';
import Localizable from 'instashop-ui/src/mixins/Localizable';

export default {
  name: 'LatestBuildAlerts',
  components: {
    PresentationHeading,
    IHeading,
    IAlert,
    IButton,
    ISwitcher,
    TextInput
  },
  mixins: [
    Localizable
  ],
  data: () => ({
    hoursToResolve: 0
  }),
  computed: {
    ...mapGetters({
      latestBuild: 'site/getLatestBuild',
      userHasRole: 'user/hasRole'
    }),
    maintenanceStep() {
      return this.latestBuild && this.latestBuild.stages && this.latestBuild.stages.service
        && this.latestBuild.stages.service.work
    },
    maintenanceEndTime() {
      return this.maintenanceStep && this.maintenanceStep.end
    },
    hasErrors() {
      const stages = this.latestBuild && this.latestBuild.stages ? this.latestBuild.stages : {}

      return Object.entries(stages).some(([stage, steps]) => {
        return Object.entries(steps).some(([step, data]) => {
          return data && data.error
        })
      })
    },
    errorTime() {
      if (!this.hasErrors) return 0

      return new Date(Object.entries(this.latestBuild.stages || {}).reduce((acc, [stage, steps]) => {
        const step = Object.entries(steps).find(([step, data]) => data && data.error)
        if (step) {
          const [stepName, data] = step
          if (new Date(data.error) > acc) {
            return new Date(data.error)
          }
        }
        return acc
      }, 0))
    },
    buildTime() {
      return Object.entries(this.latestBuild.stages || {}).reduce((acc, [stage, steps]) => {
        if (stage === 'service') return acc
        const step = Object.entries(steps).find(([step, data]) => data && data.end)
        if (step) {
          const [stepName, data] = step
          if (new Date(data.end) > acc) {
            return new Date(data.end)
          }
        }
        return acc
      }, new Date(this.latestBuild.api_created || this.latestBuild.createdAt))
    },
    buildTimeAgo() {
      return this.$moment(this.buildTime).fromNow()
    },
    currentTime() {
      return new Date();
    },
    errorHappenedAgo() {
      return this.$moment(this.errorTime).fromNow()
    },
    hoursToErrorResolve() {
      if (this.maintenanceEndTime && new Date(this.maintenanceEndTime) > new Date()) {
        return Math.ceil(((new Date(this.maintenanceEndTime)) - (new Date())) / 1000 / 60 / 60) || 1;
      }
      return 1;
    },
    timeToResolve() {
      return `<b>${this.$moment(this.currentTime).add(this.hoursToErrorResolve, 'hours').fromNow()}</b>`
    }
  },
  methods: {
    toggleBuild() {
      this.$store.dispatch('site/toggleLatestBuild', {
        id: this.latestBuild.id,
        clean: this.hasErrors,
        error: !this.hasErrors,
        hours: this.hoursToResolve
      })
    },
    changeHours() {
      if (String(this.hoursToResolve) === String(this.hoursToErrorResolve)) {
        return
      }
      this.$store.dispatch('site/toggleLatestBuild', {
        id: this.latestBuild.id,
        clean: !this.hasErrors,
        error: this.hasErrors,
        hours: this.hoursToResolve
      }).then(() => {
        this.hoursToResolve = this.hoursToErrorResolve
      })
    }
  },
  mounted() {
    this.$store.dispatch('site/loadLatestBuild', { force: true })
      .then(() => {
        this.hoursToResolve = this.hoursToErrorResolve
      })
  }
}
</script>


<style scoped lang="scss">
.latest-build {
  margin-bottom: var(--spacer-sm);
  --heading-flex-grow: 0;
  --heading-justify-content: flex-start;
  --heading-margin: 0 0 var(--spacer-2xs);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacer-2xs);

  &--success, &--error {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    --alert-font-size: var(--h4-font-size);
    text-align: center;

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      --heading-margin: 0 var(--spacer-xs) 0 0;
    }

    &-subtitle {
      margin-bottom: var(--spacer-2xs);
      text-align: center;
      font-size: var(--h5-font-size);
    }
  }

  &--hours {
    --input-background: rgba(var(--theme-white-base), .75);

    ::v-deep .sf-input__button {
      position: absolute;
      top: var(--input-icon-top, 70%);
      right: var(--input-icon-right, var(--spacer-xs));
      transform: translate3d(0, -50%, 0);
    }
    ::v-deep .heading {
      --heading-margin: 0;
    }
  }

  &--toggle {
    padding: var(--spacer-xs) var(--spacer-sm) var(--spacer-2xs);
    border: 2px dashed rgba(var(--theme-dark-base), .5);
    border-radius: var(--button-border-radius);
    margin-left: auto;
    --heading-font-size: var(--h5-font-size);
    --switcher-justify-content: flex-start;
    --switcher-margin: 0 0 var(--spacer-2xs);

    @media screen and (max-width: 767px) {
      flex-grow: 1;
    }
  }

  &--has-error {
    padding: var(--spacer-xs);
    background-color: rgba(var(--theme-danger-base), .2);
    //border-radius: 2px;
  }
}
</style>
