<template>
  <div class="site-language" v-on="$listeners">
    <component :is="flagCmp" class="site-language-flag" />
    <span class="site-language-country">{{ country }}</span>
    <!--    <Dot class="site-language-dot" />-->
    <!--    <span class="site-language-currency">{{ currency }}</span>-->
  </div>
</template>

<script>
import RU from '@/components/icons/flags/RU'
import EN from '@/components/icons/flags/EN'
import US from '@/components/icons/flags/US'
import Dot from '@/components/atoms/Dot';

export default {
  name: 'SiteLanguage',
  props: {
    lang: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: false
    }
  },
  computed: {
    flagCmp () {
      if (this.lang === 'en') return 'US'
      return this.lang.toUpperCase();
    }
  },
  components: {
    EN,
    US,
    RU,
    Dot
  }
}
</script>

<style scoped lang="scss">
.site-language {
  display: flex;
  align-items: center;
  color: var(--theme-dark);
  font-size: var(--language-font-size, 1.125rem);
  cursor: pointer;

  &-flag {
    width: var(--language-flag-width, 28px);
    height: var(--language-flag-height, 21px);
    margin-right: 0.75rem;
    flex-shrink: 0;
  }

  &-dot {
    top: var(--spacer-2xs);
  }

  &-country {
    margin-right: var(--spacer-xs);
  }

  &-currency {
    margin-right: var(--spacer-xs);
    margin-left: var(--spacer-xs);
  }
}
</style>
