import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '@/layouts/Main'
import Parent from '@/views/Parent'
import ErrorPage from '@/views/ErrorPage.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      breadcrumb: 'home'
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */'@/views/Dashboard'),
        meta: {
          breadcrumb: 'dashboard',
        },
      },
      {
        path: '/403',
        name: '403',
        component: ErrorPage,
        props: {
          title: 'title._403',
          description: 'error.forbidden'
        },
        meta: {
          breadcrumb: '_403',
        }
      },
      {
        path: '/brands',
        component: Parent,
        meta: {
          breadcrumb: 'brand-list',
          roles: ['admin']
        },
        children: [
          {
            path: '',
            name: 'brand-list',
            component: () => import(/* webpackChunkName: "brands" */ '@/views/Brands/List'),
            meta: {
              roles: ['admin']
            }
          },
          {
            path: ':code',
            name: 'admin-brand-cabinet',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Cabinet.vue'),
            meta: {
              title: 'brand-cabinet',
              breadcrumb: 'brand-cabinet',
              roles: ['admin'],
              parent: 'brand-list'
            },
          },
          {
            path: ':code/get-started',
            name: 'admin-brand-get-started',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/GetStarted'),
            meta: {
              title: 'brand-get-started',
              breadcrumb: 'brand-get-started',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/edit',
            name: 'admin-brand-edit',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Edit'),
            meta: {
              title: 'brand-edit',
              breadcrumb: 'brand-edit',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/products',
            name: 'admin-brand-products',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Products'),
            meta: {
              title: 'brand-products',
              breadcrumb: 'brand-products',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/sales',
            name: 'admin-brand-sales',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Sales.vue'),
            meta: {
              title: 'brand-sales',
              breadcrumb: 'brand-sales',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/transactions',
            name: 'admin-brand-transactions',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Transactions.vue'),
            meta: {
              title: 'brand-transactions',
              breadcrumb: 'brand-transactions',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/shipments',
            name: 'admin-brand-shipments',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Shipments.vue'),
            meta: {
              title: 'brand-shipments',
              breadcrumb: 'brand-shipments',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          },
          {
            path: ':code/shipments/:id',
            name: 'admin-brand-shipment',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Shipment.vue'),
            meta: {
              title: 'brand-shipment',
              breadcrumb: 'brand-shipment',
              roles: ['admin'],
              parent: 'admin-brand-shipments'
            },
          },
          {
            path: ':code/payouts',
            name: 'admin-brand-payouts',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Payouts'),
            meta: {
              title: 'brand-payouts',
              breadcrumb: 'brand-payouts',
              roles: ['admin'],
              parent: 'admin-brand-cabinet'
            },
          }
        ]
      },
      {
        path: '/cabinet',
        component: Parent,
        meta: {
          breadcrumb: 'brand-cabinet',
          roles: ['supplier']
        },
        children: [
          {
            path: '',
            name: 'brand-cabinet',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Cabinet'),
            meta: {
              roles: ['supplier']
            },
          },
          {
            path: 'get-started',
            name: 'brand-get-started',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/GetStarted'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            },
          },
          {
            path: 'edit',
            name: 'brand-edit',
            component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Edit'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            },
          },
          {
            path: 'products',
            name: 'brand-products',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Products'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            }
          },
          {
            path: 'sales',
            name: 'brand-sales',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Sales.vue'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            }
          },
          {
            path: 'transactions',
            name: 'brand-transactions',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Transactions.vue'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            }
          },
          {
            path: 'shipments',
            name: 'brand-shipments',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Shipments.vue'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            }
          },
          {
            path: 'shipments/:id',
            name: 'brand-shipment',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Shipment.vue'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-shipments'
            }
          },
          {
            path: 'payouts',
            name: 'brand-payouts',
            component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Payouts.vue'),
            meta: {
              roles: ['supplier'],
              parent: 'brand-cabinet'
            }
          },
        ]
      },
      {
        path: '/sites',
        component: Parent,
        meta: {
          breadcrumb: 'sites',
          roles: ['deployer']
        },
        children: [
          {
            path: '',
            name: 'sites',
            component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/List.vue'),
            meta: {
              roles: ['deployer']
            },
          },
          {
            path: 'add',
            name: 'site-add',
            component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/Add.vue'),
            meta: {
              parent: 'sites',
              roles: ['deployer']
            },
          },
          {
            path: ':id',
            name: 'site',
            component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/Details.vue'),
            meta: {
              parent: 'sites',
              roles: ['deployer']
            },
          },
          {
            path: ':id/edit',
            name: 'site-edit',
            redirect: ':id',
            meta: {
              roles: ['deployer'],
            },
          }
        ]
      }
    ]
  },
  {
    path: '/site/add',
    redirect: 'sites/add'
  },
  {
    path: '/site/:id',
    redirect: 'sites/:id'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Register')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/ForgotPass')
  },
  {
    path: '/401',
    name: '401',
    component: ErrorPage,
    props: {
      title: 'title._401',
      description: 'error.unauthorized'
    }
  },
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    props: {
      title: 'title._404',
      description: 'error.not_found'
    }
  },
  {
    path: '*',
    redirect: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

export default router
