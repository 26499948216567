<template>
  <IModal
    class="change-password-modal"
    :title="$t('forms.links.change_password')"
    :visible="visible"
    persistent
    @close="$emit('close')"
  >
    <TextInput
      v-model="oldPassword"
      name="oldPassword"
      type="password"
      required
      :label="$t('forms.register.fields.password_old')"
      :hint="makeHint('forms.register.fields.password_old')"
      :placeholder="$t('forms.register.fields.password_old_placeholder')"
      :valid="!incorrectPassword"
      :error-message="$t('error.password.incorrect')"
    />
    <TextInput
      v-model="newPassword"
      name="newPassword"
      type="password"
      required
      :label="$t('forms.register.fields.password_new')"
      :hint="makeHint('forms.register.fields.password_new')"
      :placeholder="$t('forms.register.fields.password_new_placeholder')"
    />
    <TextInput
      v-model="confirmPassword"
      name="confirmPassword"
      type="password"
      required
      :label="$t('forms.register.fields.password_confirm')"
      :hint="makeHint('forms.register.fields.password_confirm')"
      :placeholder="$t('forms.register.fields.password_confirm_placeholder')"
      :error-message="$t('error.password.dont_match')"
      :valid="validPassword"
      @input="validatePassword"
    />
    <div class="change-password-modal__buttons">
      <IButton class="sf-button--outline" @click="$emit('close')">
        {{ $t('forms.buttons.cancel') }}
      </IButton>
      <IButton
        class="sf-button--action"
        :disabled="!oldPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword"
        @click="savePassword"
      >
        {{ $t('forms.buttons.update_password') }}
      </IButton>
    </div>
  </IModal>
</template>

<script>
import { IButton, TextInput, IHeading, IModal } from 'instashop-ui';
import { SfModal } from '@storefront-ui/vue';
import Localizable from 'instashop-ui/src/mixins/Localizable'
import { debounce } from "lodash";

export default {
  name: 'ChangePasswordModal',
  mixins: [
    Localizable,
  ],
  components: {
    SfModal,
    IModal,
    IButton,
    TextInput,
    IHeading
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.incorrectPassword = false;
        this.validPassword = true;
      }
    }
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      incorrectPassword: false,
      validPassword: true,
    }
  },
  methods: {
    savePassword() {
      this.incorrectPassword = false;
      this.$store.dispatch('user/changePassword', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }).then(() => {
        this.$toast.add({
          severity: 'success',
          detail: this.$t('notifications.password_changed'),
          life: 3000
        })
        this.$emit('close');
      })
      .catch(() => {
        this.incorrectPassword = true;
      });
    },
    validatePassword: debounce(function() {
      this.validPassword = this.newPassword === this.confirmPassword;
    }, 1000),
  }
}
</script>

<style lang="scss" scoped>
.change-password-modal {
  --heading-justify-content: space-between;

  ::v-deep .sf-modal__close {
    margin-left: auto;
  }

  &__buttons {
    display: flex;
    margin-top: var(--spacer-base);
    justify-content: space-between;

    .sf-button--outline, .sf-button--action {
      --button-padding: .75rem var(--spacer-base);
    }
  }

  &__instructions {
    p {
      white-space: pre-line;
    }
  }
}
</style>
