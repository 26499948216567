<template>
  <div class="language-selector">
    <SiteLanguage
      v-show="!mounted"
      class="sf-component-select__selected"
      :lang="selectedLang"
      :country="$t('langs.' + selectedLang)"
    />
    <SfComponentSelect
      v-show="mounted"
      :selected="selectedLang"
      @change="selectLanguage"
      :cancel-label="$t('forms.buttons.cancel')"
    >
      <SfComponentSelectOption v-for="lang in languages" :key="lang.value" :value="lang.value">
        <SiteLanguage :lang="lang.value" :country="lang.label" />
      </SfComponentSelectOption>
    </SfComponentSelect>
  </div>
</template>

<script>
import { SfComponentSelect } from '@storefront-ui/vue'
import SiteLanguage from '@/components/molecules/SiteLanguage';
// import { loadLanguageAsync } from '@/dynamicI18n'; // when dynamic

export default {
  name: 'LanguageSelector',
  data () {
    return {
      mounted: false,
      languages: [
        {
          label: this.$t("langs.en"),
          value: "en"
        },
        {
          label: this.$t("langs.ru"),
          value: "ru"
        },
      ],
    }
  },
  components: {
    SiteLanguage,
    SfComponentSelect
  },
  mounted () {
    this.mounted = true;
  },
  computed: {
    selectedLang () {
      return this.$i18n.locale;
    }
  },
  methods: {
    selectLanguage (value) {
      // loadLanguageAsync(value) // when dynamic
      if (localStorage.lang !== value) {
        localStorage.lang = value;
        this.$i18n.locale = value;
      }
    }
  }
}
</script>

<style scoped>
.language-selector {
    cursor: pointer;
    --component-select-padding: 0;
    --component-select-error-message-height: 0;
    --component-select-selected-padding: .75rem var(--spacer-lg) var(--spacer-xs) 0;
}
</style>
