<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    const routeTitle = this.$route.meta && this.$route.meta.title ? this.$route.meta.title : this.$route.name
    return {
      title: this.$t('title.' + (routeTitle || 'home')),
      titleTemplate: this.$t('title._template'),
      link: [{
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Oswald:wght@500&display=swap'
      }]
    }
  }
}
</script>
